import type { Story } from 'react-insta-stories/dist/interfaces';

import {
    Children1,
    Children2,
    Children3,
    Colleagues1,
    Colleagues2,
    Colleagues3,
    Friends1,
    Friends2,
    Friends3,
    Lover1,
    Lover2,
    Lover3,
} from '~/components/story-block/story-content';

export const colleaguesStories = [
    {
        content: () => <Colleagues1 />,
    },
    {
        content: () => <Colleagues2 />,
    },
    {
        content: () => <Colleagues3 />,
    },
] as Story[];

export const friendsStories = [
    {
        content: () => <Friends1 />,
    },
    {
        content: () => <Friends2 />,
    },
    {
        content: () => <Friends3 />,
    },
] as Story[];

export const childrenStories = [
    {
        content: () => <Children1 />,
    },
    {
        content: () => <Children2 />,
    },
    {
        content: () => <Children3 />,
    },
] as Story[];

export const loverStories = [
    {
        content: () => <Lover1 />,
    },
    {
        content: () => <Lover2 />,
    },
    {
        content: () => <Lover3 />,
    },
] as Story[];
