import { createIcon } from '@chakra-ui/icons';

export const QrCode = createIcon({
    displayName: 'QrCode',
    viewBox: '0 0 32 32',
    path: (
        <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M2.58789 11.7654V6.82422C2.58789 4.61508 4.37875 2.82422 6.58789 2.82422H11.5291'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M18.3525 21.6476V20.3535C18.3525 19.2489 19.248 18.3535 20.3525 18.3535H21.6467'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M11.5291 29.6465H6.58789C4.37875 29.6465 2.58789 27.8556 2.58789 25.6465V20.7053'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M20.7063 2.82422H25.6475C27.8566 2.82422 29.6475 4.61508 29.6475 6.82422V11.7654'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M29.6475 20.7053V25.6465C29.6475 27.8556 27.8566 29.6465 25.6475 29.6465H20.7063'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M24.4707 18.3531V22.4707C24.4707 23.5753 23.5753 24.4707 22.4707 24.4707H21.1766'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M6.58789 12.5879V8.58789C6.58789 7.48332 7.48332 6.58789 8.58789 6.58789H12.5879C13.6925 6.58789 14.5879 7.48332 14.5879 8.58789V12.5879C14.5879 13.6925 13.6925 14.5879 12.5879 14.5879H8.58789C7.48332 14.5879 6.58789 13.6925 6.58789 12.5879Z'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M6.58789 23.4121V19.4121C6.58789 18.3075 7.48332 17.4121 8.58789 17.4121H12.5879C13.6925 17.4121 14.5879 18.3075 14.5879 19.4121V23.4121C14.5879 24.5167 13.6925 25.4121 12.5879 25.4121H8.58789C7.48332 25.4121 6.58789 24.5167 6.58789 23.4121Z'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M17.4121 12.5879V8.58789C17.4121 7.48332 18.3075 6.58789 19.4121 6.58789H23.4121C24.5167 6.58789 25.4121 7.48332 25.4121 8.58789V12.5879C25.4121 13.6925 24.5167 14.5879 23.4121 14.5879H19.4121C18.3075 14.5879 17.4121 13.6925 17.4121 12.5879Z'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
        </svg>
    ),
});
