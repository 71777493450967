'use client';

import Lottie from 'react-lottie-player';
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box, Flex, Icon, Img, LinkBox, LinkOverlay } from '@chakra-ui/react';
import { useMediaQuery } from '@chakra-ui/react';
import { useRouter } from 'next/navigation';

import { ButtonCore, TypographyCore } from '~/components/core-components';
import { StoryBlock } from '~/components/story-block';

import { useGetPartnersQuery } from '~/lib/partners/api';
import { useGetProductsCategoriesQuery } from '~/lib/product/api';

import spinner from '../../../public/Animation - spinner.json';
import { Gift } from '../../../public/gift';
import { Order } from '../../../public/order';
import { QrCode } from '../../../public/qrcode';
import { SmileFace } from '../../../public/smile-face';

import { childrenStories, colleaguesStories, friendsStories, loverStories } from './stories';

import styles from './styles.module.css';

export default function Page() {
    const router = useRouter();

    const [isMobile] = useMediaQuery(['(max-width: 850px)'], {
        ssr: true,
        fallback: false,
    });
    const { data: categories, isFetching: isCategoriesFetching } = useGetProductsCategoriesQuery({
        $filter: '',
    });

    const { data: partners } = useGetPartnersQuery({
        $filter: '',
    });

    return (
        <div>
            <div className={styles['container']}>
                <div className={styles['envelop-block']}>
                    <div className={styles['text-block']}>
                        <TypographyCore view='bold' className={`${styles['heading']} mb-20`}>
                            Забота, доступная каждому
                        </TypographyCore>
                        <TypographyCore view='medium' size='l' color='secondary' className={`${styles['title']} mb-40`}>
                            Порадуй или поблагодари своего близкого, даже если ты далеко
                        </TypographyCore>
                        <ButtonCore
                            view='primary'
                            size='large'
                            rightAddon={<ArrowForwardIcon w='24px' h='24px' />}
                            onClick={() => router.push('/catalog')}
                        >
                            Выбрать подарок
                        </ButtonCore>
                    </div>
                    <div className={styles['image-block']}>
                        <Box backgroundImage={'/envelope.png'} className={styles['envelop-image']}></Box>
                        <div className={styles['box-shadow']} />
                    </div>
                </div>
                <div className={styles['stories-block']}>
                    <StoryBlock title='С заботой о коллегах' stories={colleaguesStories} />
                    <StoryBlock title='С заботой о друзьях' stories={friendsStories} />
                    <StoryBlock title='С заботой о детях' stories={childrenStories} />
                    <StoryBlock title='С заботой о любимых' stories={loverStories} />
                </div>
            </div>
            <div className={styles['message-block']}>
                <div className={styles['messages-line-container']}>
                    <div className={styles['photo-block-content']}>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                justifyContent: 'space-between',
                                minHeight: '350px',
                            }}
                        >
                            <div className={styles['messages-typography']}>
                                <TypographyCore variant='h2' color='green-brand'>
                                    Уникальный сервис
                                </TypographyCore>
                                <TypographyCore variant='h2' className={'mb-20'}>
                                    для приобретения подарков на разные случаи жизни
                                </TypographyCore>
                                <TypographyCore view='medium' size='m' className='mb-28'>
                                    Первый в России сервис, позволяющий дарить подарки с помощью QR-кода и радовать близких.
                                </TypographyCore>
                            </div>
                            <div
                                style={{
                                    position: 'relative',
                                }}
                            >
                                <Img
                                    objectFit={'contain'}
                                    src={'./gift+sms.png'}
                                    w='320px'
                                    h='250px'
                                    backgroundPosition={'center'}
                                    backgroundRepeat={'no-repeat'}
                                    backgroundSize={'contain'}
                                />
                                <Box
                                    position={'relative'}
                                    border='4px solid rgba(79, 130, 100, 0.32)'
                                    backgroundColor={'rgba(255, 255, 255, 0.6)'}
                                    borderRadius={'8px'}
                                    p='12px'
                                    maxWidth={'320px'}
                                    marginTop={'8px'}
                                    left={isMobile ? '10%' : '20%'}
                                >
                                    <TypographyCore view='medium' size='m'>
                                        Пусть коктейль поднимет тебе настроение в этот пасмурный день!
                                    </TypographyCore>
                                </Box>
                                <Img
                                    objectFit={'contain'}
                                    position='relative'
                                    right={isMobile ? '2%' : '90%'}
                                    marginTop={isMobile ? '15px' : '0'}
                                    src={'./message.png'}
                                    w='306px'
                                    h='74px'
                                    backgroundPosition={'center'}
                                    backgroundRepeat={'no-repeat'}
                                    backgroundSize={'contain'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles['container']}>
                <div className={styles['steps-block']}>
                    <TypographyCore variant='h2'>Хотите порадовать близкого?</TypographyCore>
                    <TypographyCore variant='h2' color='orange-brand' className={isMobile ? 'mb-20' : 'mb-48'}>
                        Все очень просто!
                    </TypographyCore>
                    <div className={styles['grid-steps']}>
                        <div className={styles['step-item']}>
                            <div className={styles['badge']}>
                                <TypographyCore view='medium' size='l'>
                                    Шаг 1
                                </TypographyCore>
                            </div>
                            <div className={styles['step-header']}>
                                <Icon as={Gift} height={'32px'} w={'32px'} />
                                <TypographyCore variant='h3'>Выбираете подарок</TypographyCore>
                            </div>
                            <TypographyCore view='medium' size='l' color='secondary'>
                                Переходите в каталог и выбирайте подарок на любой вкус и стоимость
                            </TypographyCore>
                        </div>
                        <div className={styles['step-item']}>
                            <div className={styles['badge']}>
                                <TypographyCore view='medium' size='l'>
                                    Шаг 2
                                </TypographyCore>
                            </div>
                            <div className={styles['step-header']}>
                                <Icon as={Order} height={'32px'} w={'32px'} />
                                <TypographyCore variant='h3'>Оформляете заказ</TypographyCore>
                            </div>
                            <TypographyCore view='medium' size='l' color='secondary'>
                                Напишите пару теплых слов получателю подарка, чтобы поднять ему настроение
                            </TypographyCore>
                        </div>
                        <div className={styles['step-item']}>
                            <div className={styles['badge']}>
                                <TypographyCore view='medium' size='l'>
                                    Шаг 3
                                </TypographyCore>
                            </div>
                            <div className={styles['step-header']}>
                                <Icon as={QrCode} height={'32px'} w={'32px'} />
                                <TypographyCore variant='h3'>Получаете QR-код</TypographyCore>
                            </div>
                            <TypographyCore view='medium' size='l' color='secondary'>
                                Получайте QR-код и делитесь им с близким в любое время, когда Вам удобно
                            </TypographyCore>
                        </div>
                        <div className={styles['step-item']}>
                            <div className={styles['badge']}>
                                <TypographyCore view='medium' size='l'>
                                    Шаг 4
                                </TypographyCore>
                            </div>
                            <div className={styles['step-header']}>
                                <Icon as={SmileFace} height={'32px'} w={'32px'} />
                                <TypographyCore variant='h3'>Радуете близких</TypographyCore>
                            </div>
                            <TypographyCore view='medium' size='l' color='secondary'>
                                Получатель подарка сможет воспользоваться QR-кодом в удобное для него время
                            </TypographyCore>
                        </div>
                    </div>
                </div>
                <div className={styles['popular-products-block']}>
                    <div style={{ maxWidth: '700px', marginBottom: '32px' }}>
                        <TypographyCore variant='h2'>Мы предлагаем различный ассортимент подарков для ваших</TypographyCore>
                        <TypographyCore variant='h2' color='green-brand'>
                            презентов и благодарностей
                        </TypographyCore>
                    </div>
                    <ButtonCore
                        view='primary'
                        size='large'
                        rightAddon={<ArrowForwardIcon w='24px' h='24px' />}
                        onClick={() => router.push('/catalog')}
                    >
                        Перейти в каталог
                    </ButtonCore>
                    {isCategoriesFetching ? (
                        <Flex justifyContent={'center'}>
                            <Lottie animationData={spinner} play={true} loop={true} style={{ width: '8%', height: '8%' }} />
                        </Flex>
                    ) : (
                        <div className={styles['popular-products']}>
                            {categories?.data.items.map((item) => (
                                <LinkBox as='article' key={item.id} textAlign={'center'}>
                                    <Img
                                        src={item.icon}
                                        objectFit={'contain'}
                                        w={isMobile ? '140px' : '220px'}
                                        h='170px'
                                        backgroundPosition={'center'}
                                        backgroundRepeat={'no-repeat'}
                                        backgroundSize={'contain'}
                                    />
                                    <LinkOverlay href={`/catalog/${item.slug}`}>
                                        <TypographyCore variant='span' view='medium' color='secondary' size='l'>
                                            {item.name}
                                        </TypographyCore>
                                    </LinkOverlay>
                                </LinkBox>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className={styles['photo-block']}>
                <div className={styles['photo-line-container']}>
                    <div className={styles['photo-block-content']}>
                        <div className={styles['title-container']}>
                            <TypographyCore variant='h2' color='green-brand'>
                                Дарите эмоции,
                            </TypographyCore>
                            <TypographyCore variant='h2' className={'mb-24'}>
                                а не просто подарки
                            </TypographyCore>
                        </div>
                        {isMobile ? (
                            <div className={styles['mobile-photo-container']}>
                                <Img src={'./arrow.png'} objectFit={'cover'} className={styles['arrow-photo']} />
                                <Img src={'./mobile-image1.png'} objectFit={'cover'} className={`${styles['mobile-photo']} mb-4`}></Img>
                                <Img src={'./mobile-image2.png'} objectFit={'cover'} className={`${styles['mobile-photo']} mb-4`}></Img>
                                <Img src={'./mobile-image3.png'} objectFit={'cover'} className={`${styles['mobile-photo']} mb-4`}></Img>
                            </div>
                        ) : (
                            <Img src={'./photo-content.png'} objectFit={'cover'} className={styles['photo-content-image']} />
                        )}
                    </div>
                </div>
            </div>
            <div className={styles['partners-line']}>
                <div className={styles['animation']}>
                    <div className={styles['animation-content']}>
                        {partners?.data.items.map((item) => (
                            <div key={item.id} className={styles['partner-badge']}>
                                <TypographyCore view='medium' size='l' color='heavy'>
                                    {item.name}
                                </TypographyCore>
                            </div>
                        ))}
                    </div>
                    <div aria-hidden='true' className={styles['animation-content']}>
                        {partners?.data.items.map((item) => (
                            <div key={item.id} className={styles['partner-badge']}>
                                <TypographyCore view='medium' size='l' color='heavy'>
                                    {item.name}
                                </TypographyCore>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
