import { createIcon } from '@chakra-ui/icons';

export const SmileFace = createIcon({
    displayName: 'SmileFace',
    viewBox: '0 0 35 35',
    path: (
        <svg width='35' height='35' viewBox='0 0 35 35' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M32.0467 0.646484C31.4544 0.646484 30.9629 0.971641 30.6568 1.23211C30.6121 1.27126 30.5691 1.30871 30.5294 1.34617C30.4897 1.30871 30.4467 1.27126 30.402 1.23211C30.0959 0.971641 29.6045 0.646484 29.0121 0.646484C27.9051 0.646484 27 1.56237 27 2.70297C27 3.12175 27.0811 3.79249 27.5245 4.5841C27.9663 5.37231 28.7523 6.25244 30.1141 7.11385H30.1157C30.2415 7.19386 30.3838 7.23472 30.5294 7.23472C30.675 7.23472 30.819 7.19386 30.9431 7.11555H30.9447C32.3082 6.25244 33.0925 5.37231 33.5343 4.5841C33.9777 3.79249 34.0588 3.12175 34.0588 2.70297C34.0588 1.56237 33.1537 0.646484 32.0467 0.646484ZM32.6739 4.07509C32.3396 4.67093 31.7108 5.40976 30.5294 6.17754C29.348 5.40976 28.7192 4.67093 28.385 4.07339C28.0424 3.46223 27.9928 2.97365 27.9928 2.70297C27.9928 2.13607 28.4445 1.66792 29.0121 1.66792C29.2586 1.66792 29.5267 1.81262 29.7683 2.01861C29.8824 2.11564 29.9768 2.21438 30.0413 2.28929C30.0727 2.32674 30.0975 2.35568 30.1141 2.37611C30.1207 2.38632 30.1273 2.39313 30.129 2.39654C30.129 2.39824 30.1323 2.39994 30.1323 2.39994C30.225 2.52932 30.3722 2.60423 30.5294 2.60423C30.6866 2.60423 30.8339 2.52932 30.9265 2.39994L30.9298 2.39654C30.9298 2.39654 30.9381 2.38632 30.9464 2.37611C30.9613 2.35568 30.9861 2.32674 31.0175 2.28929C31.0821 2.21438 31.1764 2.11564 31.2906 2.01861C31.5338 1.81262 31.8002 1.66792 32.0467 1.66792C32.6143 1.66792 33.066 2.13607 33.066 2.70297C33.066 2.97365 33.0164 3.46223 32.6739 4.07509Z'
                fill='url(#paint0_linear_2132_23478)'
            />
            <path
                d='M16.0005 4.88281C23.7974 4.88281 30.1181 11.2035 30.1181 19.0005C30.1181 26.7974 23.7974 33.1181 16.0005 33.1181C8.2035 33.1181 1.88281 26.7974 1.88281 19.0005C1.88281 11.2035 8.2035 4.88281 16.0005 4.88281Z'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M10.3525 19.9414V19.9414C12.3313 25.1035 19.5778 25.2588 21.7759 20.1862L21.882 19.9414'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <circle cx='11.0592' cy='15.942' r='1.64706' fill='#034D21' />
            <path
                d='M22.5881 15.942C22.5881 16.8516 21.8506 17.589 20.941 17.589C20.0314 17.589 19.2939 16.8516 19.2939 15.942C19.2939 15.0323 20.0314 14.2949 20.941 14.2949C21.8506 14.2949 22.5881 15.0323 22.5881 15.942Z'
                fill='#034D21'
            />
            <defs>
                <linearGradient id='paint0_linear_2132_23478' x1='27.6155' y1='1.11805' x2='33.0767' y2='4.90207' gradientUnits='userSpaceOnUse'>
                    <stop stopColor='#D25900' />
                    <stop offset='1' stopColor='#FA6A00' />
                </linearGradient>
            </defs>
        </svg>
    ),
});
