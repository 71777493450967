import { createIcon } from '@chakra-ui/icons';

export const Order = createIcon({
    displayName: 'Order',
    viewBox: '0 0 32 32',
    path: (
        <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M21.8828 5.41211H16.0005H9.88281C7.67367 5.41211 5.88281 7.20297 5.88281 9.41211V25.6474C5.88281 27.8565 7.67367 29.6474 9.88281 29.6474H21.8828C24.092 29.6474 25.8828 27.8565 25.8828 25.6474V9.41211C25.8828 7.20297 24.092 5.41211 21.8828 5.41211Z'
                stroke='#034D21'
                strokeWidth='2'
            />
            <path
                d='M18.4706 7.05882H13.5294C12.6847 7.05882 12 6.37408 12 5.52941C12 4.68474 12.6847 4 13.5294 4H18.4706C19.3153 4 20 4.68474 20 5.52941C20 6.37408 19.3153 7.05882 18.4706 7.05882Z'
                fill='white'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M9.17676 12.7065C10.5101 12.079 13.7885 11.2006 16.2356 12.7065C18.6826 14.2124 21.6473 13.0198 22.8238 12.2353'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M9.41211 17.6967C10.5156 17.1267 13.2287 16.3288 15.2539 17.6967C17.279 19.0646 19.7326 17.9813 20.7062 17.2687'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M8.94141 23.8139C9.90692 23.2439 12.281 22.446 14.053 23.8139C15.825 25.1818 17.9718 24.0985 18.8238 23.3859'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
        </svg>
    ),
});
