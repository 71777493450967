import { createIcon } from '@chakra-ui/icons';

export const Gift = createIcon({
    displayName: 'Gift',
    viewBox: '0 0 32 32',
    path: (
        <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M6.58789 24.235V18.5879C6.58789 16.3788 8.37875 14.5879 10.5879 14.5879H21.4114C23.6206 14.5879 25.4114 16.3787 25.4114 18.5879V24.2349C25.4114 26.4441 23.6206 28.2349 21.4114 28.2349H10.5879C8.37875 28.2349 6.58789 26.4441 6.58789 24.235Z'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M15.8824 15.9997H6.70588C5.21146 15.9997 4 14.7882 4 13.2938C4 11.7994 5.21146 10.5879 6.70588 10.5879H15.8824H25.0588C26.5532 10.5879 27.7647 11.7994 27.7647 13.2938C27.7647 14.7882 26.5532 15.9997 25.0588 15.9997H15.8824Z'
                fill='white'
            />
            <path
                d='M15.7647 28.2349L15.8824 15.9997M15.8824 15.9997H6.70588C5.21146 15.9997 4 14.7882 4 13.2938V13.2938C4 11.7994 5.21146 10.5879 6.70588 10.5879H15.8824H25.0588C26.5532 10.5879 27.7647 11.7994 27.7647 13.2938V13.2938C27.7647 14.7882 26.5532 15.9997 25.0588 15.9997H15.8824Z'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M16 10.2171C17.3458 7.06616 22.1262 1.23889 24.7873 4.68383C27.6372 8.37327 20.0374 9.52546 16 10.2171Z'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
            <path
                d='M16 10.3539C14.6542 7.20288 9.87379 1.37561 7.21272 4.82055C4.36279 8.50999 11.9626 9.66218 16 10.3539Z'
                stroke='#034D21'
                strokeWidth='2'
                strokeLinecap='round'
            />
        </svg>
    ),
});
